import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus, faSearchMinus, faArrowLeft, faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './styles/ViewFile.css';

// Ensure the correct worker source for PDF.js is set
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const VPF = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { homework } = location.state || {};
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(1);  
  const canvasRefs = useRef([]);
  const thumbnailRefs = useRef([]);

  const handleClose = () => {
    navigate(-1);
  };

  // Load PDF when homework.files is available
  useEffect(() => {
    const loadPdf = async () => {
      console.log(homework);
      if (homework.files) {
        const pdf = await pdfjsLib.getDocument(homework.files).promise;
        setNumPages(pdf.numPages);

        // Render the pages with fixed default scale
        const renderPage = async (pageNum) => {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale }); // Use fixed scale
          const canvas = canvasRefs.current[pageNum - 1];
          const context = canvas.getContext('2d');

          const outputScale = window.devicePixelRatio || 1;
          canvas.height = viewport.height * outputScale;
          canvas.width = viewport.width * outputScale;
          canvas.style.height = `${viewport.height}px`;
          canvas.style.width = `${viewport.width}px`;

          context.setTransform(outputScale, 0, 0, outputScale, 0, 0);
          context.clearRect(0, 0, canvas.width, canvas.height);

          // Use the modern API to render the page and handle the render task with .promise
          const renderTask = page.render({ canvasContext: context, viewport });
          renderTask.promise
            .then(() => {
              console.log(`Page ${pageNum} rendered successfully`);
            })
            .catch((error) => {
              console.error(`Error rendering page ${pageNum}:`, error);
            });
        };

        const renderThumbnail = async (pageNum) => {
          const page = await pdf.getPage(pageNum);
          const thumbnailViewport = page.getViewport({ scale: 0.2 });
          const thumbnailCanvas = thumbnailRefs.current[pageNum - 1];
          const thumbnailContext = thumbnailCanvas.getContext('2d');

          thumbnailCanvas.height = thumbnailViewport.height;
          thumbnailCanvas.width = thumbnailViewport.width;

          // Render the thumbnail with .promise
          const renderTask = page.render({
            canvasContext: thumbnailContext,
            viewport: thumbnailViewport,
          });

          renderTask.promise
            .then(() => {
              console.log(`Thumbnail for page ${pageNum} rendered successfully`);
            })
            .catch((error) => {
              console.error(`Error rendering thumbnail for page ${pageNum}:`, error);
            });
        };

        // Render all pages and thumbnails with default scale
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          renderPage(pageNum);
          renderThumbnail(pageNum); // Render the thumbnail preview
        }
      }
    };

    loadPdf();
  }, [homework.files, scale]);  // Re-render when scale changes

  // Zoom In
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3));  // Max zoom to 300%
  };

  // Zoom Out
  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));  // Min zoom to 50%
  };

  const handleThumbnailClick = (pageNum) => {
    const canvasElement = canvasRefs.current[pageNum - 1];
    if (canvasElement) {
      canvasElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (<>
    

    <div className="vpf-container">
    <h2 className="vpf-heading">{homework.homeworkTitle.replace(/_/g,' ')} file</h2>
      
      <div className="vpf-info">
        <div className="vpf-info-group">
          <label htmlFor="grade" className="vpf-label">Grade:</label>
          <input
            id="grade"
            type="text"
            value={homework.grade || 'Unmarked'}
            disabled
            className="vpf-input"
          />
        </div>
        
        <div className="vpf-info-group">
          <label htmlFor="feedback" className="vpf-label">Feedback:</label>
          <textarea
            id="feedback"
            value={homework.feedback || 'No feedback available'}
            disabled
            className="vpf-textarea"
          />
        </div>
      </div>
      <div className="vpf-header">
        <div className="vpf-control-panel">
          {/* Homework title in the top-left */}
          <span className="vpf-title">{homework.homeworkTitle.replace(/_/g, ' ')}</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => setScale(scale > 1 ? scale - 0.1 : scale)} />
          <FontAwesomeIcon icon={faArrowRight} onClick={() => setScale(scale < 3 ? scale + 0.1 : scale)} />
          <FontAwesomeIcon icon={faSearchPlus} onClick={handleZoomIn} />
          <FontAwesomeIcon icon={faSearchMinus} onClick={handleZoomOut} />
          <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
        </div>
      </div>

      <div className="vpf-body">
        <div className="vpf-sidebar">
          <div className="vpf-thumbnails">
            {[...Array(numPages)].map((_, index) => (
              <canvas
                key={index}
                ref={(el) => (thumbnailRefs.current[index] = el)}
                className="vpf-thumbnail"
                onClick={() => handleThumbnailClick(index + 1)}
              />
            ))}
          </div>
        </div>

        <div className="vpf-main">
          <div className="vpf-pdf-container">
            {[...Array(numPages)].map((_, index) => (
              <canvas
                key={index}
                ref={(el) => (canvasRefs.current[index] = el)}
                className="vpf-canvas"
              />
            ))}
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default VPF;