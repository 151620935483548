import axios from 'axios';
import { jwtDecode } from "jwt-decode";


export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const loginSuccess = ({ token, userType }) => ({
  type: LOGIN_SUCCESS,
  payload: { token, userType }, 
});


export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const login = (googleAccount, password) => async (dispatch) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/login`, {
      googleAccount,
      password,
    });
    // console.log(response);
    const token = response.data.token;
    const isVerified = response.data.isVerified;
    const userType = jwtDecode(token)?.userType;
    sessionStorage.setItem('isVerified', isVerified);
    localStorage.setItem('token', token);
    localStorage.setItem('userType',userType);
    localStorage.setItem('isVerified', isVerified);
    dispatch(loginSuccess({token,userType}));
  } catch (error) {
    console.error('Login error', error);
    throw error.response ? error.response.data : new Error('Network error');
  }
};

// Thunk action for Google registration and login
export const registerWithGoogle = (googleData, userTypeParam) => async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/register`, {
      name: `${googleData.given_name || ''} ${googleData.family_name || ''}`,
      dateOfBirth: '',
      googleAccount: googleData.email,
      userType: userTypeParam, 
      password: googleData.email,
    });

    const response = await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/login`, {
      googleAccount: googleData.email,
      password: googleData.email,
    });
    const token = response.data.token;
    console.log(token);
    const decoded = jwtDecode(token);
    const userId = decoded.id;
    const userType = decoded.userType;
     axios.post(`${process.env.REACT_APP_BE_URL}/progress/new`, { userId: userId  })
            .then(response => {
                console.log("Successfully sent userId:", response.data);
            })
            .catch(error => {
                console.error("Error sending userId:", error);
            });
    localStorage.setItem('token', token);
    localStorage.setItem('isVerified',false);
    localStorage.setItem('userType',userType);
    dispatch(loginSuccess({token,userType}));
  } catch (error) {
    console.error('Registration error', error);
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const registerWithMicrosoft = (microsoftData, userTypeParam) => async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/register`, {
      name: `${microsoftData.givenName || ''} ${microsoftData.surname || ''}`,
      dateOfBirth: '',
      googleAccount: microsoftData.mail || microsoftData.userPrincipalName,
      userType: userTypeParam,
      password: microsoftData.mail || microsoftData.userPrincipalName,
    });

    const response = await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/login`, {
      googleAccount: microsoftData.mail || microsoftData.userPrincipalName,
      password: microsoftData.mail || microsoftData.userPrincipalName,
    });

    const token = response.data.token;
    const decoded = jwtDecode(token);
    const userId = decoded.id;
    const userType = decoded.userType;
     axios.post(`${process.env.REACT_APP_BE_URL}/progress/new`, { userId: userId })
            .then(response => {
                console.log("Successfully sent userId:", response.data);
            })
            .catch(error => {
                console.error("Error sending userId:", error);
            });
    localStorage.setItem('token', token);
    localStorage.setItem('isVerified',false);
    localStorage.setItem('userType',userType);
    dispatch(loginSuccess({token, userType}));
  } catch (error) {
    console.error('Registration error', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const register = (firstName, lastName, email, password, dateOfBirth, userType) => async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/register`, {
      name: `${firstName} ${lastName}`,
      dateOfBirth,
      googleAccount: email,
      userType,
      password,
    });

    const response = await axios.post(`${process.env.REACT_APP_BE_URL}/api/auth/login`, {
      googleAccount: email,
      password,
    });

    const token = response.data.token;
    localStorage.setItem('token', token);
    localStorage.setItem('isVerified',false);
    const decoded = jwtDecode(token);
    localStorage.setItem('userType',decoded.userType);
    dispatch(loginSuccess({token,userType}));
  } catch (error) {
    console.error('Registration error', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : new Error('Network error');
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('isVerified');
  localStorage.removeItem('userType');
  localStorage.removeItem('GId');
  dispatch(logout());
};
