import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import logoImageData from './logo192.png'; // Adjust the path as necessary

const StudentDetailsPDF = ({email}) => {
  const [name, setName] = useState('');
  const [courses, setCourses] = useState({});
  const [pdfUrl, setPdfUrl] = useState(null); 
  const [overallFeedback, setOverallFeedback] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch data from the API
  useEffect(() => {
    setPdfUrl(null);
    setLoading(true);
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BE_URL}/classroom/students/student-report`, {
          headers: { email: email },
        });
        setName(response.data.name);
        setCourses(response.data.courses);
        setOverallFeedback(response.data.overallFeedback);
        console.log("Fetched student data:", response.data.courses); // Log the fetched data
      } catch (error) {
        console.error("Error fetching data", error);
      } finally{
        setLoading(true);
      }
    };
    fetchStudentDetails();
  }, [email]);


  const createPDF = () => {
    const doc = new jsPDF();
  
    // Navbar
    const navbarHeight = 30; // Height of the navbar
    const pageWidth = doc.internal.pageSize.width; // Get the page width
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const currentDate = new Date().toLocaleDateString('en-GB', options);

    doc.setFillColor(0, 74, 173); // Blue color for the navbar
    doc.rect(0, 0, doc.internal.pageSize.width, navbarHeight, 'F'); // Draw navbar
  
    // Add logo to navbar
    doc.addImage(logoImageData, 'PNG', 10, 5, 15, 15, undefined, 'FAST'); // Small logo
    doc.setFontSize(14);
    doc.setTextColor(237, 183, 10); // White text color
    doc.setFont("Merriweather", "bold"); // Set font to bold for navbar
    doc.text("MrSivaScienceTutoring", 30, 15, { align: "left" }); // Position the company name beside the logo

    doc.setFontSize(12);
    doc.setTextColor(237, 183, 10);// White text color for the date

    // Add the date on the right end of the navbar
    const dateWidth = doc.getTextWidth(currentDate); // Calculate the width of the date text
    doc.text(currentDate, pageWidth - dateWidth - 10, 15, { align: "right" }); // Position the date on the right end
  
    // Line below navbar
    
    // Define the table's columns
    const tableColumn = ["Homework Title", "Submission Time", "Grade", "Feedback", "Link to the file"];
  
    // Initialize Y position for the first table
    let currentY = navbarHeight + 15; // Start just below the navbar (increased space for student name)
  
    // Set up student name with bold styling
    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0);
    doc.setFont("Merriweather", "bold");
    doc.text(`Student Name: ${name}`, 14, currentY);
    currentY += 10;
    doc.line(10, currentY, doc.internal.pageSize.width - 10, currentY); 
    currentY += 10; // Move down after student name
  
    // Iterate through each course and add a section for each
    for (const [courseName, courseDetails] of Object.entries(courses)) {
      if (courseDetails && Object.keys(courseDetails.homeworks).length > 0) {
        // Add course name as a header
        // console.log(courseName);
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0); // Black color for course name
        doc.setFont("Merriweather", "semi-bold"); // Set font to normal for course name
        doc.text(`${courseName.replace(/_/g,' ')} -`, 14, currentY);
        currentY += 6; // Move down for the table
    
        // Prepare the rows for the current course
        const tableRows = [];
        for (const [homeworkTitle, homeworkDetails] of Object.entries(courseDetails.homeworks)) {
          const studentRow = [
            homeworkTitle.replace(/_/g,' '),
            homeworkDetails.lastModified 
            ? new Date(homeworkDetails.lastModified).toLocaleString() // Convert to local time
            : "N/A", 
            homeworkDetails.grade || "No Grade",
            homeworkDetails.feedback || "No Feedback",
            homeworkDetails.link ? '' : 'N/A' // Display 'Link' where applicable
          ];
          tableRows.push(studentRow);
        }
    
        // Add table for the current course with fixed column width
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: currentY, // Start below the course header
          theme: 'grid',
          headStyles: {
            fillColor: [0, 74, 173], // Blue color for table header
            textColor: [237, 183, 10], 
            fontSize: 12,
            font: "Merriweather", // Set font for the table header
            fontStyle:"bold" // Make header bold
          },
          bodyStyles: {
            fillColor: [245, 245, 245], // Light gray for body rows
            fontSize: 10,
            textColor: [0, 0, 0], // Black text color
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255], // White for alternate rows
          },
          columnStyles: {
            0: { cellWidth: 40  }, // Homework Title
            1: { cellWidth: 30 }, // Submission Time
            2: { cellWidth: 25 }, // Grade
            3: { cellWidth: 50 }, // Feedback
            4: { cellWidth: 20 }, // Link column with a fixed width
          },
          margin: { top: 10, left: 14, right: 14 },
          didDrawCell: (data) => {
            // console.log(data);
            const columnIndex = data.column.index;
            const rowIndex = data.row.index;
        
            if (columnIndex === 4 && data.row.raw[4]!=='Link to the file') { 
              // console.log(tableRows[rowIndex][4]);
              const link = courseDetails.homeworks[Object.keys(courseDetails.homeworks)[rowIndex]].link;
        
              if (link) {
                const cellPos = data.cell;
                const textX = cellPos.x + cellPos.padding('left') + 0.5; // X position within the cell
                const textY = cellPos.y + cellPos.height / 2 ; // Y position within the cell
                
                const encodedLink = btoa(link);
                // console.log(encodedLink);
                // Draw the link inside the cell
                doc.setTextColor(0, 0, 255); // Blue for the link text
                doc.textWithLink('Link', textX, textY, { url: `${process.env.REACT_APP_REDIRECT_URI}/view-report-pdf/${encodedLink}`});
                doc.setTextColor(0, 0, 0); // Reset text color for the rest of the document
              }
            }
          },
        });

        currentY = doc.lastAutoTable.finalY + 20; // Update Y position for the next course header
      }
    }

    doc.text('Overall Feedback :', 14, currentY);
    currentY += 8;
    const text = `${overallFeedback || 'Doing Great!!'}`;
    const textWidth = doc.getTextWidth(text);
    const maxWidth = doc.internal.pageSize.width - 28; // Max width for the feedback box
    const lineHeight = 10; // Height for each line
    const lines = doc.splitTextToSize(text, maxWidth); // Split the text into lines based on max width

    const feedbackBoxHeight = lines.length * lineHeight; // Calculate height based on number of lines

    // Draw rectangle for the feedback box
    doc.rect(14, currentY, maxWidth, feedbackBoxHeight); // Dynamic height based on text

    // Add the overall feedback text inside the box
    doc.setFont("Merriweather", "bold"); // Set the font
    doc.setFontSize(12); // Font size for the feedback text
    doc.text(lines, 16, currentY + 7); // Print each line of text inside the box

    currentY += feedbackBoxHeight + 10; // Move down for the next section

    // Footer
    const footerHeight = 20; // Height of the footer
    const pageHeight = doc.internal.pageSize.height; // Total page height
    doc.setFillColor(0, 74, 173); // Blue color for the footer
    doc.rect(0, pageHeight - footerHeight, doc.internal.pageSize.width, footerHeight, 'F'); // Draw footer
  
    // Footer text
    doc.setFontSize(10);
    doc.setTextColor(255, 255, 255); // White text color for footer
    doc.setFont("Merriweather", "bold"); // Set font to bold for footer
    doc.text("Contact us: admin@mrsivasciencetutoring.co.uk   |   Phone: (+44) 7572 363039", 40, pageHeight - footerHeight + 10, { align: "left" });
  
    // Create PDF blob and return it
    return doc.output("bloburl");
  };
  

  const generatePDF = () => {
    const pdfBlob = createPDF(); // Generate PDF and get the URL
    setPdfUrl(pdfBlob);
  };

  const downloadPDF = () => {
    const pdfBlob = createPDF(); // Generate PDF
    const link = document.createElement('a'); 
    link.href = pdfBlob;
    link.download = `${name.replace(/\s/g,'-')}.pdf`; 
    link.click(); 
  };

  const removeHomework = (courseName, homeworkTitle) => {
    setCourses(prevCourses => {
      const updatedCourses = { ...prevCourses };
      delete updatedCourses[courseName].homeworks[homeworkTitle];

      // If no homeworks remain, remove the course as well
      if (Object.keys(updatedCourses[courseName].homeworks).length === 0) {
        delete updatedCourses[courseName];
      }

      return updatedCourses;
    });
  };

  const removeCourse = (courseName) => {
    setCourses(prevCourses => {
      const updatedCourses = { ...prevCourses };
      delete updatedCourses[courseName];
      return updatedCourses;
    });
  };

  const saveOverallFeedback = async () => {
    try {
      await axios.patch(`${process.env.REACT_APP_BE_URL}/classroom/students/save-feedback`, {
        email: email,
        overallFeedback: overallFeedback
      });
      alert("Overall feedback saved successfully!");
    } catch (error) {
      console.error("Error saving feedback", error);
    }
  };
  


  return (
    <div>
      <h1>----------------</h1>
      {Object.keys(courses).length > 0 ? (
        Object.entries(courses).map(([courseName, courseDetails]) => (
          <div key={courseName}>
            <h2>
              {courseName.replace(/_/g,' ')}
              <button onClick={() => removeCourse(courseName)} style={{ marginLeft: '10px', color: 'red' }}>
                &minus;
              </button>
            </h2>
            <table border="1" style={{ width: "100%", marginBottom: "20px", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>Homework Title</th>
                  <th style={{ width: "40%" }}>Feedback</th>
                  <th style={{ width: "20%" }}>Grade</th>
                  <th style={{ width: "10%" }}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(courseDetails.homeworks).map(([homeworkTitle, homeworkDetails]) => (
                  <tr key={`${courseName}-${homeworkTitle}`}>
                    <td style={{ width: "30%" }}>{homeworkTitle.replace(/_/g,' ')}</td>
                    <td style={{ width: "40%" }}>{homeworkDetails.feedback || "No Feedback"}</td>
                    <td style={{ width: "20%" }}>{homeworkDetails.grade || "No Grade"}</td>
                    <td style={{ width: "10%" }}>
                      <button onClick={() => removeHomework(courseName, homeworkTitle)} style={{ color: 'red' }}>
                        &minus;
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))
      ) : (
        <p>Loading student data...</p>
      )}
      {loading && <div>
        <h3>Overall Feedback:</h3>
        <textarea
          value={overallFeedback}
          onChange={(e) => setOverallFeedback(e.target.value)}
          // placeholder="Enter overall feedback..."
          style={{ width: '90%', height: '100px' }}
        />
        <button onClick={saveOverallFeedback}>Save Feedback</button>
      </div>}
      <button onClick={generatePDF}>Preview PDF</button>
      <button onClick={downloadPDF}>Download PDF</button>
      {pdfUrl && (
        <div style={{ marginTop: '20px' }}>
          <h3>PDF Preview:</h3>
          <iframe
            src={pdfUrl}
            width="100%"
            height="700px"
            title="PDF Preview"
          ></iframe>
        </div>
      )}
    </div>
  );
};


export default StudentDetailsPDF;