import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dashboard.css'; // Import your Tailwind CSS

function StudentList() {
    const [users, setUsers] = useState([]);
    const sortedUsers = users.sort((a, b) => new Date(b.dateRegistered) - new Date(a.dateRegistered));


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                // Fetch from the updated getUserDetails endpoint
                const response = await axios.get(`${process.env.REACT_APP_BE_URL}/api/getUserDetails`);
                // console.log("User Data:", response.data);
                setUsers(response.data); // Updated to reflect the combined user data
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUsers();
    }, []);

    return (
        <div className="p-8">
            <h1 className="text-4xl font-bold mb-6 text-black-800">Student List</h1>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr className="bg-blue-300 text-left">
                        <th className="py-2 px-4 border-b-2 border-gray-300">Name</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300">Date of Birth</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300">Email</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300">Registration Time & Date </th>
                        {/* <th className="py-2 px-4 border-b-2 border-gray-300">Last Logged In</th> New column */}
                        <th className="py-2 px-4 border-b-2 border-gray-300">Progress</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length > 0 ? (
                        users.map((user, index) => (
                            <tr key={index} className="hover:bg-gray-300">
                                <td className="py-2 px-4 border-b border-gray-300">{user.name}</td>
                                <td className="py-2 px-4 border-b border-gray-300">{new Date(user.dateOfBirth).toLocaleDateString()}</td>
                                <td className="py-2 px-4 border-b border-gray-300">{user.googleAccount}</td>
                                <td className="py-2 px-4 border-b border-gray-300">{new Date(user.dateRegistered).toLocaleString()}</td>
                                 {/* Last Logged In
                                <td className="py-2 px-4 border-b border-gray-300">
                                    {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Never logged in'}
                                </td> */}
                                <td className="py-2 px-4 border-b border-gray-300">
                                    {user.progress && Object.keys(user.progress).length > 0 ? (
                                        <ul>
                                            {Object.keys(user.progress).map((key) => (
                                                <li key={key}>
                                                    <strong>{key}:</strong> {user.progress[key].join(', ')}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <span className="text-red-600">No progress made</span>
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center py-4">No users found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default StudentList;
