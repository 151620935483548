import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login, logout } from '../redux/actions/authActions';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';
import axios from 'axios';
import '../styles/login.css';

const Login = () => {
  const [googleAccount, setGoogleAccount] = useState('');
  const [password, setPassword] = useState('');
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirect') || '/';

  useEffect(() => {
    if (error) {
      alert(error);
      // setError(''); 
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(login(googleAccount, password));
      navigate('/'); // Navigate to homepage after successful login
    } catch (err) {
      setError(err.message || 'Login failed. Please try again.');
    }
  };

  const loginWithG = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);  
      try {
        const { data: googleData } = await axios.get(
          'https://www.googleapis.com/oauth2/v1/userinfo',
          {
            params: {
              access_token: codeResponse.access_token,
            },
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        );  
        setProfile(googleData);  
        await dispatch(login(googleData.email, googleData.email));
        const isVerified = sessionStorage.getItem('isVerified');
        console.log('isVerified:', isVerified);  
  
        if (isVerified === 'true') {
          navigate(redirectUrl);
        } else {
          alert("Please complete your profile");
          navigate('/edit-profile');
        }
      } catch (err) {
        setError(err.response?.data?.error || 'Login failed.');
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => setError('Login Failed: ' + error),
  });
  

  const authHandlerMicrosoft = async (err, data) => {
    if (err) {
      console.error('Login failed:', err);
      setError(`Microsoft Login Failed: ${err.message}`);
      return;
    }

    const { accessToken, idToken, account } = data;

    if (!accessToken || !idToken || !account) {
      console.error('Missing necessary data:', data);
      setError('Microsoft Login Failed: Missing data');
      return;
    }

    setLoading(true);

    try {
      const { data: microsoftData } = await axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setProfile(microsoftData);
      const email = microsoftData.mail || microsoftData.userPrincipalName;

      await dispatch(login(email, email)); // Dispatch Microsoft login action
      if (sessionStorage.getItem('isVerified')) {
        navigate(redirectUrl);
      } else {
        alert("Please complete your profile");
        navigate('/edit-profile');
      }
    } catch (err) {
      setError(err.response?.data?.error || `Failed to fetch user info from Microsoft: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const logOut = () => {
    googleLogout();
    dispatch(logout());
    setProfile(null);
    setGoogleAccount('');
    setPassword('');
  };

  return (
    <div className="login-container">
      {loading && (
        <div className="register-loading-overlay">
          <div className="register-spinner"></div>
        </div>
      )}
      {/* <h2 className="login-heading" >Login</h2> */}
      <div className="google-login">
        <button type="button" onClick={loginWithG} className='buttonStyle'>
        <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google Logo" className='iconStyle' />
          Login with Google
        </button>
      </div>

      <div className="microsoft-login">
        <MicrosoftLogin
          clientId={process.env.REACT_APP_MS_CLIENT_ID}
          authCallback={authHandlerMicrosoft}
          redirectUri={window.location.origin}
          children={
            <button className="ms-buttonStyle">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                alt="Microsoft Logo"
                className="ms-iconStyle"
              />
              Login with Microsoft
            </button>
          }/>
      </div>

      <div className='login-register-container '>
        <div className="login-line"></div>
        <p className="login-info-text">Don't have an account? Please register here</p>
          <button 
            type="button" 
            onClick={() => navigate('/register')} 
            className="register-buttonStyle"
          >
            Register as new user
          </button>
      </div>

      {error? <p className='error-tag' >{error}. Please, Try again</p>: ""}
      {/* <div className="create-account">
      <p>Don't have an account? <Link to="/register"><strong>Create one</strong></Link></p>
      </div> */}
    </div>
  );
};

export default Login;
