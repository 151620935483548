import React, { useRef } from 'react';
import { PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, 
         BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, 
         FormFields, FormDesigner, PageOrganizer, Inject } from '@syncfusion/ej2-react-pdfviewer';

const TestMarker = ({ pdfUrl }) => {
  const pdfViewerRef = useRef(null);

  const inkMode = () => {
    if (pdfViewerRef.current) {
      pdfViewerRef.current.annotation.setAnnotationMode('Ink');
    }
  };

  return (
    <div>
      <button onClick={inkMode}>Draw Ink</button>
      <div className='control-section'>
        <PdfViewerComponent
          ref={pdfViewerRef}
          id="container"
          documentPath={pdfUrl}
          resourceUrl="https://cdn.syncfusion.com/ej2/24.1.41/dist/ej2-pdfviewer-lib"
          style={{ height: '640px' }}>
          <Inject services={[ Toolbar, Annotation, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView,
                              Print, TextSelection, TextSearch, FormFields, FormDesigner, PageOrganizer]} />
        </PdfViewerComponent>
      </div>
    </div>
  );
};

export default TestMarker;
