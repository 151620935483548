import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { uploadFileToS3 } from '../s3';
import { jwtDecode } from 'jwt-decode';
import { jsPDF } from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles/ImageToPdf.css';

const SubmitHomework = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading,setLoading] = useState(false);
  const course = localStorage.getItem('course');
  const homeworkTitle = localStorage.getItem('homeworkTitle'); // Get homework title
  const token = localStorage.getItem('token');
  const [pdfs, setPdfs] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const csplit = course.replace(' ', '_');
  const hsplit = homeworkTitle.replace(' ', '_');

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      const sanitized_name = decoded.name
        .replace(/[^a-zA-Z\s]/g, '')
        .replace(/\s+/g, '_');
      setEmail(decoded.email);
      setName(sanitized_name);
    }
  }, [token]);

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState('');
  const [comments, setComments] = useState('');
  const [images, setImages] = useState([]);
  const [uploadType, setUploadType] = useState('');
  const navigate = useNavigate();

  let mergedPdfBlob = null; 

  const handleFileChange = async(e) => {
    const selectedFiles = Array.from(e.target.files);
    const newPdfFiles = selectedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setPdfFiles((prevFiles) => [...prevFiles, ...newPdfFiles]);
    // if (selectedFiles.length > 0) {
    //   mergedPdfBlob = await generateMergedPDF(selectedFiles);
    //   const pdfPreviewUrl = URL.createObjectURL(mergedPdfBlob);
    //   setFilePreview(pdfPreviewUrl);
    //   setFile(mergedPdfBlob);
    // }
  };
  const onPdfDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const reorderedPdfs = Array.from(pdfFiles);
    const [movedPdf] = reorderedPdfs.splice(source.index, 1);
    reorderedPdfs.splice(destination.index, 0, movedPdf);
    setPdfFiles(reorderedPdfs);
  };
  const removePdf = (index) => {
    setPdfFiles(pdfFiles.filter((_, i) => i !== index));
  };
    

  // Handle image upload and display previews
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...prevImages, ...newImages]);
  };

  // Remove image from the list
  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  // Handle drag and drop for images
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const updatedImages = Array.from(images);
    const [movedImage] = updatedImages.splice(source.index, 1);
    updatedImages.splice(destination.index, 0, movedImage);
    setImages(updatedImages);
  };

  // Generate PDF from images
  // const generatePDF = async () => {
  //   if (images.length === 0) {
  //     alert('Please upload at least one image.');
  //     return;
  //   }

  //   const pdf = new jsPDF();
  //   images.forEach((image, index) => {
  //     const img = new Image();
  //     img.src = image;

  //     img.onload = () => {
  //       const imgWidth = pdf.internal.pageSize.getWidth();
  //       const imgHeight = img.height * imgWidth / img.width;
  //       if (index > 0) {
  //         pdf.addPage();
  //       }
  //       pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);

  //       if (index === images.length - 1) {
  //         const blob = pdf.output('blob');
  //         setFile(blob); 
  //       }
  //     };
  //   });
  // };

  const generateMergedPDF = async (selectedFiles) => {
    const mergedPdf = await PDFDocument.create();
    for (const file of selectedFiles) {
      const pdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    const mergedPdfFile = await mergedPdf.save();
    return new Blob([mergedPdfFile], { type: 'application/pdf' });
  };

  const generatePDF = async () => {
    if (images.length === 0) {
      alert('Please upload at least one image.');
      return;
    }
  
    const pdf = new jsPDF();
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const img = new Image();
      img.src = image;
  
      await new Promise((resolve) => {
        img.onload = () => {
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (img.height * imgWidth) / img.width;
          if (i > 0) {
            pdf.addPage();
          }
          pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);
          resolve();  
        };
        img.onerror = () => {
          console.error('Image load error');
          resolve(); 
        };
      });      
    }
  
    // const blob = pdf.output('blob');
    // console.log('Generated PDF Blob:', blob);
    // setFile(blob); 
    return pdf.output('blob')
  };
  

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let fileToUpload = mergedPdfBlob || file;
    try {
      if (pdfFiles.length > 0) {
        const filesToMerge = pdfFiles.map((pdf) => pdf.file);
        mergedPdfBlob = await generateMergedPDF(filesToMerge);
        fileToUpload = mergedPdfBlob;
      }

      console.log('Generating PDF or using selected file...'); 

    if (uploadType === 'images') {
      const generatedFile = await generatePDF();
      if (!generatedFile) {
        alert('Failed to generate PDF. Please try again.');
        return; 
      }
      fileToUpload = generatedFile; 
    }
  
      // Check if file is set after generating the PDF
      if (!fileToUpload) {
        alert('Please upload a PDF file or generate it from images.');
        setLoading(false);
        return;
      }

      const path = `sstClassroom/${csplit}/${hsplit}/${name}`;
      const fileLink = await uploadFileToS3(fileToUpload, path);
      const response = await axios.put(
        `${process.env.REACT_APP_BE_URL}/classroom/students/submit-homework`,
        {
          course,
          homeworkTitle,
          email,
          name,
          link: fileLink,
          lastModified: new Date().toISOString(),
          comments,
        }
      );

      if (response.status === 200) {
        alert('Homework submitted successfully');
        navigate(`/sstclass/${course}`);
      }
    } catch (error) {
      console.error('Error submitting homework:', error);
      alert('Error submitting homework');
    }finally{
      setLoading(false);
    }
  };

  // Toggle between image or PDF upload
  const handleUploadTypeChange = (type) => {
    setUploadType(type);
    setFile(null);
    setImages([]);
  };

  // Function to format the homework title
  const formatHomeworkTitle = (title) => {
    return title.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="submit-homework-container">
      <h1 className="submit-homework-heading">Submit Homework: {course.replace(/_/g,' ')} / {homeworkTitle.replace(/_/g,' ')}</h1>
      <form onSubmit={handleSubmit}>
        {/* Choose Upload Type */}
        <div className="upload-type-container">
          <label className="upload-type-option">
            <input
              type="radio"
              value="pdf"
              checked={uploadType === 'pdf'}
              onChange={() => handleUploadTypeChange('pdf')}
            />
            Upload PDF / PDFs 
          </label>
          <label className="upload-type-option">
            <input
              type="radio"
              value="images"
              checked={uploadType === 'images'}
              onChange={() => handleUploadTypeChange('images')}
            />
            Upload Images
          </label>
        </div>

        {/* Upload PDF */}
        {uploadType === 'pdf' && (
          <div>
            <label htmlFor="file-upload" className="custom-file-upload">
              Choose PDF / PDFs 
            </label>
            <p>You can select multiple pdfs at a time</p>
            <input type="file" id="file-upload" accept="application/pdf" multiple onChange={handleFileChange} className="upload-pdf-input" required />
            <DragDropContext onDragEnd={onPdfDragEnd}>
              <Droppable droppableId="pdfs">
                {(provided) => (
                  <div
                    className="droppable-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {pdfFiles.map((pdf, index) => (
                      <Draggable
                        key={index}
                        draggableId={`pdf-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="sst-pdf-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <iframe
                              src={pdf.preview}
                              title={`preview ${index}`}
                              className="sst-preview-pdf"
                              width="300px"
                              height="250px"
                            ></iframe>
                            <button
                              type="button"
                              onClick={() => removePdf(index)}
                              className="sst-remove-btn"
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder && (
                      <div className="droppable-placeholder"></div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>


            {/* {filePreview && (
              <div className="pdf-preview">
                <h3>PDF Preview:</h3>
                <iframe
                  src={filePreview}
                  title="PDF Preview"
                  width="300px"
                  height="250px"
                ></iframe>
              </div>
            )} */}
          </div>
        )}

        {/* Upload Images */}
        {uploadType === 'images' && (
          <div>
            <label htmlFor="image-upload" className="custom-file-upload-images">
              Choose Images
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
              className="upload-image-input"
              required
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="images">
                {(provided) => (
                  <div
                    className="droppable-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {images.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="sst-image-container"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <img
                              src={image}
                              alt={`preview ${index}`}
                              className="sst-preview-image"
                            />
                            <button
                              onClick={() => removeImage(index)}
                              className="sst-remove-btn"
                            >
                              X
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder && (
                      <div className="droppable-placeholder"></div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        <div className="comments-section">
          <label className="comments-label">Comments:</label>
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="comments-textarea"
            placeholder="Add any comments regarding your submission"
          />
        </div>

        <button
                type="submit"
                className="sst-generate-btn"
                disabled={loading}
            >
                {loading ? 'Submitting...' : 'Submit Homework'} 
            </button>
      </form>
    </div>
  );
};

export default SubmitHomework;