import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dashboard.css'; // Import your Tailwind CSS

function Dashboard({activeUsers, usersActive}) {
  console.log(usersActive);
  const [registeredUsers, setRegisteredUsers] = useState(0);
  const [loggedInUsers, setLoggedInUsers] = useState(0);
  const [activeUserDet, setActiveUserDet] = useState([]);

  useEffect(() => {
    // Function to fetch user statistics
    const fetchUserStatistics = async () => {
      try {
        const [registeredResponse, loggedInResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BE_URL}/api/statistics/registered-users`),
          axios.get(`${process.env.REACT_APP_BE_URL}/api/statistics/logged-in-users`)
        ]);
        
        setRegisteredUsers(registeredResponse.data.totalRegisteredUsers);
        setLoggedInUsers(loggedInResponse.data.totalLoggedInUsers);
      } catch (error) {
        console.error('Error fetching user statistics:', error);
      }
    };

    fetchUserStatistics();
  }, []);


  return (
    <div className="flex-grow p-8 ml-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Card for Total Registered Users */}
        <div className="bg-yellow-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Total Registered Users</h3>
          <p className="text-4xl mt-4">{registeredUsers || 'No registered users'}</p>
        </div>

        {/* Card for Total Logged-In Students */}
        {/* <div className="bg-yellow-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Total Logged-In Students</h3>
          <p className="text-4xl mt-4">{loggedInUsers }</p>
        </div> */}

        {/* Card for Active Users */}
        <div className="bg-yellow-400 text-black text-center p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold">Active Users</h3>
          <p className="text-4xl mt-4">{activeUsers }</p>
        </div>
      </div>
      {/* table */}
      <div className="mt-8 p-6">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Active Users</h3>
        <table className="min-w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-200 text-gray-700 text-left">
              <th className="py-2 px-4 border-b">Username</th>
              <th className="py-2 px-4 border-b">Details</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(usersActive) && usersActive.length > 0 ? (
                usersActive.map((user, index) => {
                  try {
                    const decodedUser = atob(user);
                    const [username, details] = decodedUser.split('-');
                    return (
                      <tr key={username} className="hover:bg-gray-100 transition duration-200">
                        <td className="py-2 px-4 border-b text-gray-700">{username}</td>
                        <td className="py-2 px-4 border-b text-gray-600">{details || 'No details available'}</td>
                      </tr>
                    );
                  } catch (e) {
                    console.error('Error decoding user:', e);
                    return null; // Skip rendering this user if there's an error
                  }
                })
              ) : (
                <tr>
                  <td colSpan="2" className="text-center py-2">No active users found</td>
                </tr>
              )}
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default Dashboard;
