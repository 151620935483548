import React, { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import '../styles/PdfViewer.css'; 
import { uploadFileToS3 } from './s3'; // Import the S3 upload function

const PdfViewer = ({ pdfUrl, path }) => {
  const viewer = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        // licenseKey: `${process.env.REACT_APRYSE_LICENCE_KEY}`,
        licenseKey: 'demo:1725374099693:7e55742e0300000000bc3be6fa4a58ae212ad40042e40e3566b88876da',
        initialDoc: pdfUrl,
      },
      viewer.current,
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations, PDFNet } = instance.Core;

      documentViewer.addEventListener('documentLoaded', () => {
        const rectangleAnnot = new Annotations.RectangleAnnotation({
          PageNumber: 1,
          X: 100,
          Y: 150,
          Width: 200,
          Height: 50,
          Author: annotationManager.getCurrentUser(),
        });

        annotationManager.addAnnotation(rectangleAnnot);
        annotationManager.redrawAnnotation(rectangleAnnot);
      });

      // Function to export the edited PDF
      const saveEditedPdf = async () => {
        setLoading(true);
        try 
          {const doc = documentViewer.getDocument();
          const xfdfString = await annotationManager.exportAnnotations();
          
          // Merge annotations and get the file as a blob
          const options = { xfdfString };
          const data = await doc.getFileData(options);
          const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });

          // const baseUrl = process.env.REACT_APP_S3_BASE_URL;
          // const filePath = pdfUrl.replace(baseUrl,''); 
          console.log(path);
          const filePath = path;
          console.log(filePath);
          await uploadFileToS3(blob, filePath);
          alert('saved file successfully');
        } catch (error) {
          console.error('Error saving PDF:', error);
          alert('Failed to save PDF');
        } finally {
          setLoading(false);
        }
      };

      const saveButton = document.getElementById('save-button');
      saveButton.addEventListener('click', saveEditedPdf);
      return () => {
        saveButton.removeEventListener('click', saveEditedPdf);
      };
    });
  }, [pdfUrl]);

  return (
    <div className={`pdf-viewer-wrapper ${loading ? 'loading' : ''}`}>
      {loading && <div className="loading-spinner"></div>}
      <div className={`pdf-viewer-content ${loading ? 'disabled' : ''}`} ref={viewer}></div>
      <button 
        id="save-button" 
        className="pdf-viewer-save-button" 
        disabled={loading}
      >
        {loading ? 'Saving...' : 'Save PDF'}
      </button>
    </div>
  );
};

export default PdfViewer;
