import React, { useEffect, useState, useCallback } from "react";
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
// import physics from "../images/physics.jpg";
// import chemistry from "../images/chemistry.jpg";
// import biology from "../images/biology.jpg";
// import math from "../images/math.jpg";
import { useNavigate } from "react-router-dom"; 
import "../styles/revision_set.css";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import { jwtDecode } from "jwt-decode";


const RevisionSet = () => {
  // const token = useSelector((state)=> state.auth.token);
  const token = localStorage.getItem('token');
  const navigate = useNavigate(); 
  const [userType, setUserType] = useState('');

  const handleAccessExamQuestions = useCallback(() => {
    navigate("/access_exam_paper_folder/physics_qp");
  }, [navigate]);

  const handleQuiz = useCallback(() => {
    navigate("/access_exam_paper_folder/quiz_set");
  }, [navigate]);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUserType(decoded.userType);
        // console.log(decoded);
        const f_name = decoded.name.split(' ')[0];
        const email=decoded.email;

        // Hash the first name
        // const hash = CryptoJS.SHA256(f_name).toString(CryptoJS.enc.Hex);

        // Combine the GId and the hash
        const GId_o = `${f_name}-${email}`;
        const GId = `${f_name}-${GId_o}`;
        // console.log(GId)
        localStorage.setItem('GId', GId_o );
        
         // Send custom user ID to GTM's dataLayer
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   'event': 'user_login',  // Custom event name
        //   'userId': GId,         // Custom user ID
        //   'user_type': decoded.userType,  // You can send more user details if needed
        //   'page_path': window.location.pathname
        // });'
        window.gtag('config', 'G-W28YMHJSP8', {
          user_id: GId,  // Send custom user ID to GA4
          user_type: decoded.userType,  // Send additional user details if needed
          page_path: window.location.pathname
        });
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, [token]);


  const handleHomework = useCallback(() => {
    if (token) {
        const decoded = jwtDecode(token);
        // console.log(decoded);
        const userId = decoded.id;
        const url = `${process.env.REACT_APP_NEWWINDOW_URI}?userId=${encodeURIComponent(userId)}`;
        window.open(url, "_blank");
    } else {
        alert("Login Required");
        navigate("/login");
      }
    }, [token, navigate]);

    const handleClassroom = useCallback(() => {
      navigate('/sst-class');
    }, [navigate]);

  return (
    <div className="revision-set_container">
      <div className="access_exam_questions_btn">
        <div className="button_container">
        <button onClick={handleAccessExamQuestions}>
           Exam Questions
        </button>

        <button onClick={handleQuiz}>A-Level</button>

        <button onClick={handleHomework}>Quiz</button>
        {(userType === 'sstClassroom' || userType ==='admin' || userType === 'developer' )&&  <button onClick={handleClassroom}>Access Classroom</button> }
        </div>
      </div>
    </div>
  );
};

export default RevisionSet;