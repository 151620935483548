import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Classes.css'; 

const MarkerHome = () => {
  const navigate = useNavigate();

  // Array of links and their paths
  const links = [
    { name: 'Show classes', path: '/marker/courses' },
    { name: 'Checklist', path: '/checklist' },
  ];

  // Handle navigation on click
  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <div className="sst-classes-container">
      <h2 className="sst-classes-heading">Marker's Lobby</h2>
      {links.map((link, index) => (
        <div
          key={index}
          className="sst-class-link"
          onClick={() => handleLinkClick(link.path)}
        >
          {link.name.replace('_', ' ')}
        </div>
      ))}
    </div>
  );
};

export default MarkerHome;
